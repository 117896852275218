import useSWF from '@raulpesilva/swf'
import { useCallback, useState } from 'react'
import { instanceGet, instancePost } from '../../api/utils'

export const useCreateAlert = (setOpen: any) => {
  const [closedGroups, setClosedGroups] = useState<any>([])
  const { loading, send, error } = useSWF<any>('/ocurrences', instancePost)
  const {
    loading: loadingClosedGroup,
    send: sendClosedGroup,
    error: errorClosedGroup,
  } = useSWF<{ data: any }>('/closedGroups/list', instanceGet)

  const create = useCallback(
    async (data: any) => {
      try {
        const { result } = await send({ ...data, noCache: true })
        if (result?.data) {
          setOpen(false)
        }
      } catch {}
    },
    [send, setOpen]
  )

  const listClosedGroups = useCallback(async () => {
    try {
      const { result } = await sendClosedGroup({ noCache: false })
      setClosedGroups(result?.data)
    } catch (error) {
      console.log('error', error)
    }
  }, [sendClosedGroup])

  return { create, loading, error, listClosedGroups, closedGroups, loadingClosedGroup, errorClosedGroup }
}
