import loadable from '@loadable/component'
import React, { memo, useEffect, useMemo } from 'react'
import { BrowserRouter, Route, RouteChildrenProps, RouteProps, Switch } from 'react-router-dom'
import ChatBar from '../components/ChatBar'
import { useAlerts, useUser } from '../hook'
import { SearchAlerts, SearchCameras } from '../pages'
import NiceModal from '../libs/react-nice-modal'
import PreviewImages from '../components/PreviewImages'
import { FEATURE_FLAGS } from '../domain'

const Box = loadable(() => import('../components/shared/Box/Box'))
const ChatList = loadable(() => import('../components/ChatList'))
const Header = loadable(() => import('../components/Header'))
const NavigationButtons = loadable(() => import('../components/NavigationButtons'))
const CameraDetail = loadable(() => import('../components/CameraDetail'))
const SideNav = loadable(() => import('../components/SideNav'))

const AlertDetail = loadable(() => import('../pages/AlertDetail'))
const AlertOptions = loadable(() => import('../pages/AlertOptions'))
const Alerts = loadable(() => import('../pages/Alerts'))
const Cameras = loadable(() => import('../pages/Cameras'))
const SideNavaCameraDetail = loadable(() => import('../pages/SideNavCameraDetail'))
const MapList = loadable(() => import('../pages/MapList'))
const Home = loadable(() => import('../pages/Home'))
const Maps = loadable(() => import('../pages/Maps'))
const Login = loadable(() => import('../pages/Login'))

const PrivateRoute = memo(({ path, exact, ...rest }: RouteProps) => {
  const { user } = useUser()

  const RenderRoute = useMemo(() => {
    return user?._id ? <Route path={path} exact={exact} {...rest} /> : <Route path={path} component={Login} />
  }, [exact, path, rest, user])
  return RenderRoute
})

const stateMenu = {
  list: ['/cameras', '/alertas', '/mensagens', '/map'],
  detail: ['/alertas/alerta/'],
}

const PrivateRoutes = ({ history }: RouteChildrenProps) => {
  const { preFetchAlerts } = useAlerts()

  const isList = () => {
    return stateMenu.list.some(item => history.location.pathname.includes(item))
  }

  const isDetail = () => {
    return stateMenu.detail.some(item => history.location.pathname.includes(item))
  }

  useEffect(() => {
    preFetchAlerts()
  }, [preFetchAlerts])

  return (
    <Box flex={1}>
      <Header />
      <Box row flex={1} justifyContent="space-between">
        <SideNav isList={isList()} isDetail={isDetail()}>
          <Switch>
            <Route exact path="/" component={NavigationButtons} />
            <Route exact path="/alertas" component={Alerts} />
            <Route exact path="/cameras" component={Cameras} />
            <Route exact path="/mensagens" component={ChatList} />
            <Route exact path="/maps" component={MapList} />
            <Route path="/search" component={NavigationButtons} />
            <Route path="/maps/camera/:cameraId" component={MapList} />
            <Route path="/alertas/alerta/:alertId" component={AlertDetail} />
            {!FEATURE_FLAGS.NEW_TIMELINE_API && <Route path="/cameras/camera/:cameraId" component={CameraDetail} />}
            {FEATURE_FLAGS.NEW_TIMELINE_API && (
              <Route path="/cameras/camera/:cameraId" component={SideNavaCameraDetail} />
            )}
          </Switch>
        </SideNav>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/alertas" component={Home} />
          <Route exact path="/cameras" component={Home} />
          <Route exact path="/mensagens" component={Home} />
          <Route exact path="/maps" component={Maps} />
          <Route path="/maps/camera/:cameraId" component={Maps} />
          <Route path="/search/camera/:camera" component={SearchCameras} />
          <Route path="/search/alerta/:alerta" component={SearchAlerts} />
          <Route path="/cameras/camera/:cameraId" component={Home} />
          <Route path="/alertas/alerta/:alertId" component={AlertOptions} />
        </Switch>
      </Box>
      <ChatBar />
      <PreviewImages />
      <NiceModal.NiceModalProvider />
    </Box>
  )
}

const Routes = () => {
  return (
    <BrowserRouter>
      <PrivateRoute path="/" component={PrivateRoutes} />
    </BrowserRouter>
  )
}

export default Routes
