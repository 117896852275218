import React, { useMemo, useState } from 'react'
import { FiAlertTriangle, FiSearch } from 'react-icons/fi'
import { useHistory, useParams } from 'react-router-dom'
import { Button, CardCamera, CardSkeleton, IconButton, Input, Timeline, Wrapper } from '../../components'
import CreateAlert from '../../components/CreateAlert'
import { Camera, dispatchPlayback, useCamerasSelect } from '../../states'
import * as S from './styles'

const selectCamera = (cameras: Camera[], cameraId: Camera['_id']) => {
  return cameras.find(camera => camera._id === cameraId)
}

const SideNavCameraDetail = () => {
  const { cameraId } = useParams<{ cameraId: string }>()
  const history = useHistory()
  const [alertCreationDialogIsOpen, setAlertCreationDialogOpen] = useState<boolean>(false)
  const [filter, setFilter] = useState('')
  const cameras = useCamerasSelect()
  const camera = useMemo(() => selectCamera(cameras, cameraId), [cameraId, cameras])

  const handleClick = () => {
    history.push(`/cameras/camera/${cameraId}`)
    dispatchPlayback(undefined)
  }

  const handleGoBack = () => history?.push('/')
  const handleChangeFilter = (value: string) => {
    setFilter(value)
  }

  return (
    <S.Container>
      <CreateAlert
        open={alertCreationDialogIsOpen}
        setOpen={() => setAlertCreationDialogOpen(!alertCreationDialogIsOpen)}
        camera={camera}
      />
      <Button onClick={handleGoBack} label="Fechar" rounded variant="secondary" alignSelf="flex-end" />
      <S.Button
        label="Criar Alerta"
        leftIcon={() => <FiAlertTriangle size="48px" />}
        onClick={() => setAlertCreationDialogOpen(true)}
        outlined
      />
      <Wrapper row justifyContent="space-evenly" p="p3" animation="show">
        <Input
          variant="primary"
          outlined
          rounded
          placeholder="Digite o dia ou mes aqui"
          onChange={handleChangeFilter}
        />
        <IconButton icon={FiSearch} ml="m2" rounded variant="primary" aria-label="Pesquisar" />
      </Wrapper>
      {!!camera?._id && (
        <CardCamera
          key={camera?._id}
          id={camera?._id}
          title={camera?.name}
          location={camera?.formattedAddress}
          image={camera?.sponsorLogoUrl}
          onClick={handleClick}
          restrict={camera?.restrictedAccess}
          selected={camera?._id === cameraId}
          showCase
        />
      )}
      {!camera?._id && <CardSkeleton />}
      <Timeline cameraId={cameraId} filter={filter} />
    </S.Container>
  )
}

export default SideNavCameraDetail
