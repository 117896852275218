import React, { MouseEvent, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ALERT_IMAGES, ALERT_TYPE_DICTIONARY } from '../../../domain'
import { Component } from '../../../libs/react-nice-modal/@types'
import { Alert } from '../../../states'
import { formattedDateTimeToDateString } from '../../../utils'
import * as S from './styles'

const variants = {
  visible: (custom: number) => ({
    translateY: custom * 100,
    zIndex: 99,
    transition: { duration: 0.3 },
  }),
  hidden: {
    translateY: -1000,
    zIndex: 98,
    transition: { duration: 0.3 },
  },
}
const TIME_TO_DESAPEAR = 10

export const AlertToastComponent: Component<{ alert: Alert }> = ({ close, index, alert }) => {
  const [time, setTime] = useState(TIME_TO_DESAPEAR)
  const history = useHistory()

  useEffect(() => {
    const timer = setInterval(() => setTime(prev => prev - 1), 1000)
    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    if (time < 0) close()
  }, [close, time])

  if (!alert) return null
  const {
    type = 'others',
    status,
    formattedAddress,
    createdAt,
    hasAttachments,
    formattedCreationDate,
    category,
    _id,
  } = alert

  const handleClose = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    close()
  }

  const handleNavigateDetail = () => {
    history?.push(`/alertas/alerta/${_id}`)
    close()
  }

  return (
    <S.Container
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={variants}
      custom={index}
      alert={category}
      onClick={handleNavigateDetail}
    >
      <S.CloseButton onClick={handleClose}>
        <S.CloseIcon />
      </S.CloseButton>
      <S.Box>
        <S.Image src={ALERT_IMAGES[type]} status={status} alt={ALERT_TYPE_DICTIONARY[type]} />
        <S.Wrapper>
          <S.Title>{ALERT_TYPE_DICTIONARY[type]}</S.Title>
          <S.Description>{formattedAddress}</S.Description>
        </S.Wrapper>
        <S.WrapperTime haveAttachments={hasAttachments}>
          {hasAttachments && <S.Attachments />}
          <S.Time>{formattedCreationDate}</S.Time>
          {!!createdAt && <S.Date>{formattedDateTimeToDateString(createdAt)}</S.Date>}
        </S.WrapperTime>
      </S.Box>
      <S.Bar>
        <S.Progress progress={(100 / TIME_TO_DESAPEAR) * time} />
      </S.Bar>
    </S.Container>
  )
}
