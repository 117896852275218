import React, { useEffect, useMemo, useState } from 'react'
import { Provider, useDispatch } from 'react-redux'
import io from 'socket.io-client'
import { ThemeProvider } from 'styled-components'
import { selectedBackend } from './api'
import applicationTheme from './assets/theme'
import { GlobalStyle } from './assets/theme/GlobalStyle'
import { AlertToastComponent, Confirm } from './components'
import { useLogin, useTheme, useUser } from './hook'
import { ReAppearProvider } from './lib/ReAppear'
import { BaseToast, ContainerToast } from './lib/ReAppear/web/components'
import NiceModal from './libs/react-nice-modal'
import LocalStorage from './local-storage'
import { store } from './redux'
import { appendMessageToChat } from './redux/chats'
import Routes from './routes'
import './style.css'

const Toast = props => {
  return (
    <BaseToast {...props}>
      <h1 onClick={props.close}>Toast</h1>
      <h2>{props.title}</h2>
    </BaseToast>
  )
}

NiceModal.register('notification-alert', AlertToastComponent)

const App = () => {
  const { restoreSession } = useLogin()
  const { user } = useUser()
  const dispatch = useDispatch()
  const [socket, setSocket] = useState()
  const sound = useMemo(() => new Audio(require('./assets/sound/bell.mp3')), [])

  useEffect(() => {
    restoreSession()
  }, [restoreSession])

  const fetchContextId = () => {
    return LocalStorage.getItem('contextId') || (Math.random() * 9999).toString()
  }

  useEffect(() => {
    if (user && user._id) {
      const contextId = fetchContextId()
      if (!socket) {
        setSocket(
          io(selectedBackend.REACT_APP_SOCKET_ORIGIN, {
            path: '/rio/socket.io',
            query: { userId: user._id, contextId },
          })
        )
      }
    }
  }, [user, socket])

  useEffect(() => {
    if (!(socket && socket.on)) {
      return
    }

    socket.on('connect', () => {
      console.log('> Realtime messages engine connected')
    })

    socket.on('DISCONNECT', () => {
      console.log('Disconnected')
    })

    socket.on('NEW_NOTIFICATION', envelope => {
      if (envelope.type === 'chatMessage') {
        sound.play()
        const id = envelope.meta.chatId
        dispatch(appendMessageToChat(id, envelope.meta.message))
      }
    })

    socket.on('SELF_CONTEXT_ID', contextId => {
      LocalStorage.setItem('contextId', contextId)
    })

    socket.on('NEW_OCCURRENCE', envelope => {
      sound.play()
      const alert = envelope?.data ?? {}
      NiceModal.showModal('notification-alert', { alert })
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket])

  return (
    <React.Fragment>
      <GlobalStyle />
      <Routes />
    </React.Fragment>
  )
}

const Root = () => {
  const { theme } = useTheme()

  return (
    <ThemeProvider theme={applicationTheme[theme]}>
      <ReAppearProvider
        modals={{
          confirm: { component: Confirm },
        }}
        toasts={{
          toast: { component: Toast },
          Container: { component: ContainerToast, config: { contentStyle: { right: 0 } } },
        }}
      >
        <Provider store={store}>
          <App />
        </Provider>
      </ReAppearProvider>
    </ThemeProvider>
  )
}

export default Root
