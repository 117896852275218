export const ALERT_IDENTIFIERS = {
  fire: 'fire',
  flood: 'flood',
  treeFall: 'treeFall',
  accident: 'accident',
  irregularWasteDisposal: 'irregularWasteDisposal',
  irregularSidewalk: 'irregularSidewalk',
  lackOfLight: 'lackOfLight',
  holeInTheRoad: 'holeInTheRoad',
  irregularOccupation: 'irregularOccupation',
  irregularCommerce: 'irregularCommerce',
  vandalism: 'vandalism',
  dengueBreeding: 'dengueBreeding',
  theft: 'theft',
  murder: 'murder',
  dangerousDriving: 'dangerousDriving',
  drugTrafficking: 'drugTrafficking',
  kidnapping: 'kidnapping',
  violenceAgainstWomen: 'violenceAgainstWomen',
  sexualCrime: 'sexualCrime',
  shooting: 'shooting',
  animalAbuse: 'animalAbuse',
  fight: 'fight',
  homeInvasion: 'homeInvasion',
  fugitive: 'fugitive',
  disturbanceOfPublicOrder: 'disturbanceOfPublicOrder',
  hateCrime: 'hateCrime',
  disappearance: 'disappearance',
  others: 'others',
  deleted: 'deleted',
} as const

export const ALERT_IMAGES = {
  theft: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/roubo.png',
  murder: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/homicidio.png',
  dangerousDriving: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/direcao.png',
  drugTrafficking: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/trafico.png',
  kidnapping: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/sequestro.png',
  violenceAgainstWomen: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/penha.png',
  sexualCrime: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/sexual.png',
  shooting: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/tiroteio.png',
  animalAbuse: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/animais.png',
  fight: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/briga.png',
  homeInvasion: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/invasao.png',
  fugitive: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/foragido.png',
  disturbanceOfPublicOrder: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/orderPublica.png',
  hateCrime: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/odio.png',
  fire: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/incendio.png',
  flood: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/alagamento.png',
  treeFall: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/arvore.png',
  accident: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/acidente.png',
  irregularWasteDisposal: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/lixo.png',
  irregularSidewalk: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/calcada.png',
  lackOfLight: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/luz.png',
  holeInTheRoad: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/buraco.png',
  irregularOccupation: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/ocupacaoIrregular.png',
  irregularCommerce: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/comercio.png',
  vandalism: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/vandalismo.png',
  others: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/others.png',
  dengueBreeding: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/dengue.png',
  disappearance: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/desaparecimento.png',
} as const

export const ALERT_TYPE_DICTIONARY = {
  [ALERT_IDENTIFIERS.disappearance]: 'Desaparecimento',
  [ALERT_IDENTIFIERS.dangerousDriving]: 'Direção perigosa',
  [ALERT_IDENTIFIERS.drugTrafficking]: 'Tráfico de Drogas',
  [ALERT_IDENTIFIERS.kidnapping]: 'Sequestro',
  [ALERT_IDENTIFIERS.violenceAgainstWomen]: 'Maria da Penha',
  [ALERT_IDENTIFIERS.sexualCrime]: 'Crime sexual',
  [ALERT_IDENTIFIERS.shooting]: 'Tiroteio',
  [ALERT_IDENTIFIERS.animalAbuse]: 'Maus tratos animais',
  [ALERT_IDENTIFIERS.theft]: 'Roubo',
  [ALERT_IDENTIFIERS.fight]: 'Briga',
  [ALERT_IDENTIFIERS.homeInvasion]: 'Invasão de domicílio',
  [ALERT_IDENTIFIERS.fugitive]: 'Foragido',
  [ALERT_IDENTIFIERS.disturbanceOfPublicOrder]: 'Perturbação da ordem pública',
  [ALERT_IDENTIFIERS.hateCrime]: 'Crime de ódio',
  [ALERT_IDENTIFIERS.fire]: 'Incêndio',
  [ALERT_IDENTIFIERS.flood]: 'Alagamento',
  [ALERT_IDENTIFIERS.treeFall]: 'Queda de árvore',
  [ALERT_IDENTIFIERS.accident]: 'Acidente',
  [ALERT_IDENTIFIERS.irregularWasteDisposal]: 'Descarte irregular de lixo',
  [ALERT_IDENTIFIERS.irregularSidewalk]: 'Calçada irregular',
  [ALERT_IDENTIFIERS.lackOfLight]: 'Falta de luz',
  [ALERT_IDENTIFIERS.holeInTheRoad]: 'Buraco na via',
  [ALERT_IDENTIFIERS.irregularOccupation]: 'Ocupação irregular',
  [ALERT_IDENTIFIERS.irregularCommerce]: 'Comércio irregular',
  [ALERT_IDENTIFIERS.vandalism]: 'Vandalismo',
  [ALERT_IDENTIFIERS.dengueBreeding]: 'Criadouro de dengue',
  [ALERT_IDENTIFIERS.murder]: 'Homicídio',
  [ALERT_IDENTIFIERS.others]: 'Outros',
} as const

export const ALERT_CATEGORIES = {
  crime: 'crime',
  urbanIssue: 'urbanIssue',
  others: 'others',
} as const

export const ALERT_TYPES = {
  [ALERT_IDENTIFIERS.disappearance]: {
    label: 'Desaparecimento',
    identifier: ALERT_IDENTIFIERS.disappearance,
    category: ALERT_CATEGORIES.crime,
  },
  [ALERT_IDENTIFIERS.dangerousDriving]: {
    label: 'Direção perigosa',
    identifier: ALERT_IDENTIFIERS.dangerousDriving,
    category: ALERT_CATEGORIES.crime,
  },
  [ALERT_IDENTIFIERS.drugTrafficking]: {
    label: 'Tráfico de Drogas',
    identifier: ALERT_IDENTIFIERS.drugTrafficking,
    category: ALERT_CATEGORIES.crime,
  },
  [ALERT_IDENTIFIERS.kidnapping]: {
    label: 'Sequestro',
    identifier: ALERT_IDENTIFIERS.kidnapping,
    category: ALERT_CATEGORIES.crime,
  },
  [ALERT_IDENTIFIERS.violenceAgainstWomen]: {
    label: 'Maria da Penha',
    identifier: ALERT_IDENTIFIERS.violenceAgainstWomen,
    category: ALERT_CATEGORIES.crime,
  },
  [ALERT_IDENTIFIERS.sexualCrime]: {
    label: 'Crime sexual',
    identifier: ALERT_IDENTIFIERS.sexualCrime,
    category: ALERT_CATEGORIES.crime,
  },
  [ALERT_IDENTIFIERS.shooting]: {
    label: 'Tiroteio',
    identifier: ALERT_IDENTIFIERS.shooting,
    category: ALERT_CATEGORIES.crime,
  },
  [ALERT_IDENTIFIERS.animalAbuse]: {
    label: 'Maus tratos animais',
    identifier: ALERT_IDENTIFIERS.animalAbuse,
    category: ALERT_CATEGORIES.crime,
  },
  [ALERT_IDENTIFIERS.theft]: {
    label: 'Roubo',
    identifier: ALERT_IDENTIFIERS.theft,
    category: ALERT_CATEGORIES.crime,
  },
  [ALERT_IDENTIFIERS.fight]: {
    label: 'Briga',
    identifier: ALERT_IDENTIFIERS.fight,
    category: ALERT_CATEGORIES.crime,
  },
  [ALERT_IDENTIFIERS.homeInvasion]: {
    label: 'Invasão de domicílio',
    identifier: ALERT_IDENTIFIERS.homeInvasion,
    category: ALERT_CATEGORIES.crime,
  },
  [ALERT_IDENTIFIERS.fugitive]: {
    label: 'Foragido',
    identifier: ALERT_IDENTIFIERS.fugitive,
    category: ALERT_CATEGORIES.crime,
  },
  [ALERT_IDENTIFIERS.disturbanceOfPublicOrder]: {
    label: 'Perturbação da ordem pública',
    identifier: ALERT_IDENTIFIERS.disturbanceOfPublicOrder,
    category: ALERT_CATEGORIES.crime,
  },
  [ALERT_IDENTIFIERS.hateCrime]: {
    label: 'Crime de ódio',
    identifier: ALERT_IDENTIFIERS.hateCrime,
    category: ALERT_CATEGORIES.crime,
  },
  [ALERT_IDENTIFIERS.murder]: {
    label: 'Assassinato',
    identifier: ALERT_IDENTIFIERS.murder,
    category: ALERT_CATEGORIES.crime,
  },
  [ALERT_IDENTIFIERS.fire]: {
    label: 'Incêndio',
    identifier: ALERT_IDENTIFIERS.fire,
    category: ALERT_CATEGORIES.urbanIssue,
  },
  [ALERT_IDENTIFIERS.flood]: {
    label: 'Enchente',
    identifier: ALERT_IDENTIFIERS.flood,
    category: ALERT_CATEGORIES.urbanIssue,
  },
  [ALERT_IDENTIFIERS.treeFall]: {
    label: 'Queda de árvore',
    identifier: ALERT_IDENTIFIERS.treeFall,
    category: ALERT_CATEGORIES.urbanIssue,
  },
  [ALERT_IDENTIFIERS.accident]: {
    label: 'Acidente',
    identifier: ALERT_IDENTIFIERS.accident,
    category: ALERT_CATEGORIES.urbanIssue,
  },
  [ALERT_IDENTIFIERS.irregularWasteDisposal]: {
    label: 'Descarte irregular de lixo',
    identifier: ALERT_IDENTIFIERS.irregularWasteDisposal,
    category: ALERT_CATEGORIES.urbanIssue,
  },
  [ALERT_IDENTIFIERS.irregularSidewalk]: {
    label: 'Calçada irregular',
    identifier: ALERT_IDENTIFIERS.irregularSidewalk,
    category: ALERT_CATEGORIES.urbanIssue,
  },
  [ALERT_IDENTIFIERS.lackOfLight]: {
    label: 'Falta de luz',
    identifier: ALERT_IDENTIFIERS.lackOfLight,
    category: ALERT_CATEGORIES.urbanIssue,
  },
  [ALERT_IDENTIFIERS.holeInTheRoad]: {
    label: 'Buraco na via',
    identifier: ALERT_IDENTIFIERS.holeInTheRoad,
    category: ALERT_CATEGORIES.urbanIssue,
  },
  [ALERT_IDENTIFIERS.irregularOccupation]: {
    label: 'Ocupação irregular',
    identifier: ALERT_IDENTIFIERS.irregularOccupation,
    category: ALERT_CATEGORIES.urbanIssue,
  },
  [ALERT_IDENTIFIERS.irregularCommerce]: {
    label: 'Comércio irregular',
    identifier: ALERT_IDENTIFIERS.irregularCommerce,
    category: ALERT_CATEGORIES.urbanIssue,
  },
  [ALERT_IDENTIFIERS.vandalism]: {
    label: 'Vandalismo',
    identifier: ALERT_IDENTIFIERS.vandalism,
    category: ALERT_CATEGORIES.urbanIssue,
  },
  [ALERT_IDENTIFIERS.dengueBreeding]: {
    label: 'Criador de dengue',
    identifier: ALERT_IDENTIFIERS.dengueBreeding,
    category: ALERT_CATEGORIES.urbanIssue,
  },
  [ALERT_IDENTIFIERS.others]: {
    label: 'Outros',
    identifier: ALERT_IDENTIFIERS.others,
    category: ALERT_CATEGORIES.others,
  },
} as const
