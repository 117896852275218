import React, { useEffect, useState } from 'react'
import { FiX } from 'react-icons/fi'
import { ALERT_TYPES } from '../../domain'
import { useUser } from '../../hook'
import { useCreateAlert } from '../../hook/useCreateAlert'
import { Button } from '../shared'
import IlustraType from '../shared/Ilustra-type'
import * as S from './styled'

type keys = keyof typeof ALERT_TYPES
const ALERT_KEYS = Object.keys(ALERT_TYPES) as unknown as keys[]
const ALERT_TYPES_FILTER = ALERT_KEYS.map((type: keyof typeof ALERT_TYPES) => ALERT_TYPES[type])

type AlertType = {
  type: string
  category: string
}

const CreateAlert = ({ open, setOpen, camera }: any) => {
  const [typeAlertSelected, setTypeAlertSelected] = useState<AlertType>({ type: '', category: '' })
  const [description, setDescription] = useState('')
  const [category, setCategory] = useState('crime')
  const { user } = useUser()
  const { create, loading, listClosedGroups } = useCreateAlert(setOpen)

  useEffect(() => {
    listClosedGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCreateAlert = () => {
    const alert = {
      owner: user._id,
      type: typeAlertSelected.type,
      category: typeAlertSelected.category,
      cameraId: camera._id,
      description: description,
      createdByYellowSquad: true,
      formattedAddress: camera.formattedAddress,
      location: { latitude: camera.location.coordinates[0], longitude: camera.location.coordinates[1] },
      closedGroupsToNotify: [],
    }
    create(alert)
  }

  if (!open) {
    return null
  }

  return (
    <S.Wrapper>
      <S.Container>
        <div>
          <S.Header>
            <S.HeaderWrapper>
              <S.Subtitle>Novo Alerta</S.Subtitle>
              <Button
                rounded
                label=""
                onClick={() => setOpen(false)}
                variant="secondary"
                rightIcon={FiX}
                style={{
                  overflow: 'initial',
                  background: 'transparent',
                  border: 'none',
                  height: 'inherit',
                  padding: 0,
                }}
              />
            </S.HeaderWrapper>
            <S.WrapperTypes>
              <S.SelectCategory selected={category === 'crime'} onClick={() => setCategory('crime')}>
                Criminalidade
              </S.SelectCategory>
              <S.SelectCategory selected={category === 'urbanIssue'} onClick={() => setCategory('urbanIssue')}>
                Zeladoria
              </S.SelectCategory>
              <S.SelectCategory selected={category === 'others'} onClick={() => setCategory('others')}>
                Outros
              </S.SelectCategory>
            </S.WrapperTypes>
          </S.Header>
          <S.Content>
            <S.GroupIcons>
              {ALERT_TYPES_FILTER.map(
                (item, index) =>
                  item.category === category && (
                    <S.WrapperIlustra
                      selected={typeAlertSelected?.type === item.identifier}
                      onClick={() => setTypeAlertSelected({ type: item.identifier, category: item.category })}
                    >
                      <IlustraType key={index} type={item.identifier} size={50} />
                      <p>{ALERT_TYPES[item.identifier].label}</p>
                    </S.WrapperIlustra>
                  )
              )}
            </S.GroupIcons>
            <S.Subtitle left style={{ margin: '1em 0 0.5em 0' }}>
              Descrição
            </S.Subtitle>
            <S.TextArea onChange={(e: any) => setDescription(e.target.value)} />
          </S.Content>
        </div>
        <Button
          label="Criar alerta"
          isLoading={loading}
          onClick={handleCreateAlert}
          style={{ width: '100%', borderRadius: '0 0 8px 8px' }}
        />
      </S.Container>
    </S.Wrapper>
  )
}

export default CreateAlert
