import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { Button, CameraList, IconButton, Input, Wrapper } from '../../components'
import { BaseInputProps } from '../../components/shared/Inputs/types'
import { useCameras } from '../../hook/useCameras'
import { CamerasProp, filterCameras } from '../../states'
import * as Styled from './styles'
import { BaseCamerasProps } from './types'

const Cameras: React.FC<BaseCamerasProps> = ({ history }) => {
  const { cameras, loading, listCameras } = useCameras()
  const inputRef = useRef<BaseInputProps>(null)
  const [filteredCameras, setFilteredCameras] = useState([] as CamerasProp)
  const [notFound, setNotFound] = useState(false)

  useEffect(() => {
    listCameras()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCloseList = useCallback(() => {
    history?.push('/')
  }, [history])

  const handleFiltered = useCallback(() => {
    const filtered = filterCameras(inputRef.current?.value || '')
    if (!filtered.length) {
      setNotFound(true)
      return
    }
    setNotFound(false)
    setFilteredCameras(filtered)
  }, [])

  const handleNavigateAlert = () => {
    history?.push('/alertas')
  }

  return (
    <Styled.Container>
      <Button label="Fechar" rounded variant="secondary" alignSelf="flex-end" onClick={handleCloseList} />
      <Wrapper row justifyContent="space-evenly" py="p2" animation="show">
        <Button label="Alertas" variant="secondary" rounded onClick={handleNavigateAlert} />
        <Button label="Câmeras" variant="primary" rounded />
      </Wrapper>
      <Wrapper row justifyContent="space-evenly" p="p3" animation="show">
        <Input
          ref={inputRef}
          variant="primary"
          outlined
          rounded
          placeholder="Digite o que deseja achar"
          onPressEnter={handleFiltered}
        />
        <IconButton icon={FiSearch} ml="m2" rounded variant="primary" onClick={handleFiltered} aria-label="Pesquisar" />
      </Wrapper>
      {notFound ? (
        <Styled.NotFound>Pesquisa não encontrada</Styled.NotFound>
      ) : (
        <CameraList loading={loading} cameras={filteredCameras.length ? filteredCameras : cameras} />
      )}
    </Styled.Container>
  )
}

export default Cameras
