import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { AlertList, Button, IconButton, Input, Wrapper } from '../../components'
import { BaseInputProps } from '../../components/shared/Inputs/types'
import { useAlerts } from '../../hook'
import { AlertsProp, filterAlerts } from '../../states'
import * as S from './styles'
import { BaseAlertsProps } from './types'

const Alerts: React.FC<BaseAlertsProps> = ({ history }) => {
  const { alerts, loading, listAlerts } = useAlerts()
  const inputRef = useRef<BaseInputProps>(null)
  const [filteredAlerts, setFilteredAlerts] = useState([] as AlertsProp)
  const [notFound, setNotFound] = useState(false)

  const handleNavigateCamera = () => {
    history?.push('/cameras')
  }

  const handleCloseList = () => {
    history?.push('/')
  }

  const handleFiltered = useCallback(() => {
    const filtered = filterAlerts(inputRef.current?.value || '')
    if (!filtered.length) {
      setNotFound(true)
      return
    }
    setNotFound(false)
    setFilteredAlerts(filtered)
  }, [])

  useEffect(() => {
    listAlerts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <S.Container>
      <Button label="Fechar" rounded variant="secondary" alignSelf="flex-end" onClick={handleCloseList} />
      <Wrapper row justifyContent="space-evenly" py="p5" animation="show">
        <Button label="Alertas" rounded variant="primary" />
        <Button label="Câmeras" rounded variant="secondary" onClick={handleNavigateCamera} />
      </Wrapper>
      <Wrapper row justifyContent="space-evenly" p="p3" animation="show">
        <Input
          ref={inputRef}
          variant="primary"
          outlined
          rounded
          placeholder="Digite o que deseja achar"
          onPressEnter={handleFiltered}
        />
        <IconButton icon={FiSearch} ml="m2" rounded variant="primary" onClick={handleFiltered} aria-label="Pesquisar" />
      </Wrapper>
      {notFound ? (
        <S.NotFound>Pesquisa não encontrada</S.NotFound>
      ) : (
        <AlertList alerts={filteredAlerts.length ? filteredAlerts : alerts} loading={loading} />
      )}
    </S.Container>
  )
}

export default Alerts
