import { createGetReState, createReState, createReStateDispatch, createReStateSelect } from '@raulpesilva/re-state'

export type User = {
  name: string
  _id: string
  email: string
  type: string
  iat: number
  avatar: string
  roles: string[]
}

const RESOURCE = 'user'

export const useUserState = createReState<User>(RESOURCE, {} as User)
export const useUserStateSelect = createReStateSelect<User>(RESOURCE)
export const getUserState = createGetReState<User>(RESOURCE)
export const dispatchUserState = createReStateDispatch<User>(RESOURCE)
export const resetUserState = () => dispatchUserState({} as User)
