import styled, { css } from 'styled-components'
import { themeColor, themeColorHighlight } from '../../assets/theme'

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`

export const Container = styled.div.attrs(() => {})`
  background-color: ${themeColorHighlight('primary')};
  box-sizing: border-box;
  border-radius: 8px;
  width: 36%;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${themeColor('primary')};
`

export const Header = styled.div`
  border-radius: 8px 8px 0 0;
`

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  border-radius: 8px 8px 0 0;
`

export const Content = styled.div`
  padding: 1em;
`

export const WrapperTypes = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SelectCategory = styled.div.attrs(() => {})`
  cursor: pointer;
  border-radius: 0;
  width: 33.33%;
  border: none;
  border-bottom: ${({ selected }) => selected && '2px solid #373737'};
  border-color: ${themeColor('primary')};
  background: transparent;
  text-align: center;
  padding: 0.7em 0;
`

export const Subtitle = styled.p.attrs(() => {})`
  font-size: 1em;
  font-weight: 600;
  ${({ left }) =>
    left &&
    css`
      width: 100%;
    `}
`

export const TextArea = styled.textarea.attrs(() => {})`
  resize: none;
  width: 100%;
  min-height: 10vh;
  border-radius: 8px;
  outline: none;
  padding: 1%;
  background-color: ${themeColorHighlight('primary')};
  color: ${themeColor('primary')};
`

export const GroupIcons = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  min-height: 7.45em;
`

export const WrapperIlustra = styled.div.attrs(() => {})`
  cursor: pointer;
  justify-content: center;
  display: flex;
  svg {
    margin: 0.3em;
    opacity: 0.5;
  }
  ${({ selected }) =>
    selected &&
    css`
      svg {
        opacity: 1;
      }
    `}
  p {
    position: absolute;
    display: none;
    background-color: #000;
    color: #fff;
    border-radius: 6px;
    font-size: 0.6em;
    padding: 0.4em;
    text-align: center;
    z-index: 9;
    bottom: 0;
    font-weight: 700;
  }
  &:hover {
    position: relative;
    z-index: 99;
    & > p {
      display: block;
    }
  }
`

export const Highlight1 = styled.div.attrs(() => {})`
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 100%;
  top: -150px;
  right: -150px;
  background-color: ${themeColor('primary')}0D;
  opacity: 0.6;
`
export const Highlight2 = styled.div.attrs(() => {})`
  position: absolute;
  background-color: ${themeColor('primary')}0D;
  width: 220px;
  height: 220px;
  border-radius: 100%;
  top: -110px;
  right: -110px;
`

export const Back = styled.div.attrs(() => {})`
  width: 30vw;
  display: flex;
  justify-content: flex-end;
`

export const HeaderCloseGroups = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ListClosedGroups = styled.div`
  max-height: 20vh;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  justify-content: space-between;
`

export const SelectClosedGroup = styled.div.attrs(() => {})`
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.5em;
  font-size: 0.8em;
  max-height: 2em;
`
