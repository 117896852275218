import { createReState, createReStateSelect, createReStateDispatch, createGetReState } from '@raulpesilva/re-state'

type Search = string

const KEY = 'search'
export const searchInitialValue = ''

export const useSearchState = createReState<Search>(KEY, searchInitialValue)
export const useSearchSelect = createReStateSelect<Search>(KEY)
export const searchStateDispatch = createReStateDispatch<Search>(KEY)
export const getSearch = createGetReState<Search>(KEY)
export const resetSearch = () => searchStateDispatch(searchInitialValue)
