import { createReState, createReStateSelect, createReStateDispatch, createGetReState } from '@raulpesilva/re-state'

type PlaylistState = boolean

const KEY = 'playlistState'
export const initialValue = true

export const usePlaylistState = createReState<PlaylistState>(KEY, initialValue)
export const usePlaylistStateSelect = createReStateSelect<PlaylistState>(KEY)
export const dispatchPlaylistState = createReStateDispatch<PlaylistState>(KEY)
export const getPlaylistState = createGetReState<PlaylistState>(KEY)
export const resetPlaylistState = () => dispatchPlaylistState(initialValue)
