import { useReStateSelector } from '@raulpesilva/re-state'
import { AnimatePresence, AnimateSharedLayout } from 'framer-motion'
import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Playlist, Video } from '../../components'
import { useCameras } from '../../hook/useCameras'
import { getCameraById, useSelectCameraPlayback } from '../../states'
import { isSameCameras, mosaicSelector } from '../../states/useMosaicState'
import * as Styled from './styles'
interface IParams {
  cameraId?: string
}

const Home = () => {
  const { cameraId } = useParams<IParams>()
  const { cameras, listCameras } = useCameras()
  const playback = useSelectCameraPlayback()

  const mosaicCameras = useReStateSelector(mosaicSelector, isSameCameras)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedCamera = useMemo(() => getCameraById(cameraId), [cameraId, cameras])

  useEffect(() => {
    listCameras()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const streaming = useMemo(() => playback || selectedCamera?.streamingURL, [playback, selectedCamera])

  return (
    <Styled.Container>
      {!selectedCamera && <Playlist />}
      <AnimateSharedLayout type="crossfade">
        <Styled.WrapperVideo selected={!!cameraId}>
          <AnimatePresence>
            {!selectedCamera &&
              mosaicCameras.map((camera, index) => (
                <Video
                  layoutId={camera._id}
                  key={camera._id}
                  position={index}
                  name={camera.name}
                  address={camera.formattedAddress}
                  url={camera.streamingURL}
                />
              ))}
            {selectedCamera && (
              <Video
                name={selectedCamera.name}
                address={selectedCamera.formattedAddress}
                layoutId={selectedCamera._id}
                selected
                url={streaming}
                isPlayback={!!playback}
              />
            )}
          </AnimatePresence>
        </Styled.WrapperVideo>
      </AnimateSharedLayout>
    </Styled.Container>
  )
}

export default Home
