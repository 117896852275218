import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  align-items: center;
  & > div {
    margin-top: ${props => props.theme.margin.m2};
  }
`
