import loadable from '@loadable/component'
import React, { useMemo, useEffect } from 'react'
import { FiX } from 'react-icons/fi'
import { RouteChildrenProps, useParams } from 'react-router-dom'
import { ALERT_IMAGES, ALERT_TYPE_DICTIONARY } from '../../domain'
import { useAlerts } from '../../hook'
import { AlertAttachment } from '../../states'
import * as S from './styles'
import { BaseAlertDetailProps } from './types'

const Slider = loadable(() => import('react-slick'))
const Button = loadable(() => import('../../components/shared/Buttons/PrimaryButton'))
const UserAvatar = loadable(() => import('../../components/shared/Avatar/UserAvatar'))
const Wrapper = loadable(() => import('../../components/shared/Box/Wrapper'))

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const AlertDetail: React.FC<BaseAlertDetailProps & RouteChildrenProps> = ({ history }) => {
  const { alertId } = useParams<{ alertId: string }>()
  const { loading, alert, listAlerts } = useAlerts(alertId)

  useEffect(() => {
    listAlerts()
  }, [listAlerts])

  const handleGoBack = () => {
    history.goBack()
  }

  const userName = useMemo(() => {
    if (!alert) return 'Loading...'
    return alert.anonymous || !alert?.userName?.trim() ? 'Usuário anonimo' : alert.userName
  }, [alert])

  const alertDate = useMemo(() => {
    if (!alert) return 'Loading...'
    const date = Intl.DateTimeFormat('pt-BR').format(new Date(alert.createdAt))
    const hour = new Date(alert.createdAt).toLocaleTimeString()
    return `${date} ${hour}`
  }, [alert])
  return (
    <S.Container>
      <Button
        style={{ alignSelf: 'flex-end' }}
        mr="m5"
        rounded
        label="Fechar"
        onClick={handleGoBack}
        variant="secondary"
        rightIcon={FiX}
      />

      <S.Bar alert={alert?.category}>
        {loading && <div>loading</div>}
        {alert && <UserAvatar src={ALERT_IMAGES[alert.type]} />}
        {alert && <S.Title>{ALERT_TYPE_DICTIONARY[alert.type]}</S.Title>}
        <S.Code>Código: {alert?.trackingCode ?? ''}</S.Code>
        <S.WrapperDate>
          <S.Date>{alertDate}</S.Date>
          <S.FormattedCreationDate>{alert?.formattedCreationDate ?? ''}</S.FormattedCreationDate>
        </S.WrapperDate>
      </S.Bar>
      <Wrapper row flex={1}>
        <Wrapper px="p10" pt="p3" flex={1}>
          <Wrapper row>
            <S.Name>Nome:</S.Name>
            <S.UserName>{userName}</S.UserName>
          </Wrapper>
          <Wrapper pt="p3">
            <S.Description>Descrição:</S.Description>
            <S.UserDescription>{alert?.description ?? ''}</S.UserDescription>
          </Wrapper>
          {alert?.hasAttachments && (
            <Wrapper style={{ width: '55.755vh' }} pt="p3">
              <Slider {...settings}>
                {alert.attachments.map(attachment => {
                  return <RenderAttachment attachment={attachment} key={attachment._id} />
                })}
              </Slider>
            </Wrapper>
          )}
        </Wrapper>
        <Wrapper pt="p3" px="p10" style={{ minWidth: '350px', maxWidth: '600px', width: '100%' }}>
          <S.Description>Histórico de interações:</S.Description>
          {alert?.history.map((action, index) => {
            const isLastPosition = alert.history.length - 1 === index
            return (
              <S.BoxHistory key={action._id}>
                <Wrapper row alignItems="center">
                  <S.BoxIcon></S.BoxIcon>
                  <S.TitleHistory>{action.event}</S.TitleHistory>
                </Wrapper>
                {!!action?.description && (
                  <Wrapper row alignItems="center" my="m1">
                    <S.Line lastPosition={isLastPosition} />
                    <S.DescriptionHistory>{action.description}</S.DescriptionHistory>
                  </Wrapper>
                )}
              </S.BoxHistory>
            )
          })}
        </Wrapper>
      </Wrapper>
    </S.Container>
  )
}

export const RenderAttachment = ({ attachment }: { attachment: AlertAttachment }) => {
  switch (attachment.mimetype) {
    case 'image/jpeg':
    case 'image/jpg':
    case 'image/png':
    case 'image/bmp':
    case 'image/webp':
    case 'image/svg+xml':
      return (
        <S.ImageBox>
          <S.Image src={attachment.location} />
        </S.ImageBox>
      )
    case 'video/mp4':
    case 'video/x-msvideo':
    case 'video/x-flv':
    case 'video/3gpp':
    case 'video/quicktime':
    case 'video/x-ms-wmv':
      return (
        <S.ImageBox>
          <S.Video url={attachment.location} width="100%" height="100%" playing={false} controls />
        </S.ImageBox>
      )
    default:
      return null
  }
}

export default AlertDetail
