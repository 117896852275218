import { AxiosResponse } from 'axios'
import React, { useMemo } from 'react'
import useSWR from 'swr'
import { CardSkeleton, CardTimeline } from '..'
import { instanceGet } from '../../api/utils'
import { ITimeline } from '../../requests'
import * as S from './styles'

const TIMELINE_ITEMS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 11, 22, 33, 44]

interface TimelineProps {
  cameraId?: string
  filter?: string
}

const Loading = () => {
  return (
    <S.Container>
      {TIMELINE_ITEMS.map(item => (
        <CardSkeleton key={item} />
      ))}
    </S.Container>
  )
}

export const Timeline = ({ cameraId, filter }: TimelineProps) => {
  const { data, error } = useSWR<AxiosResponse<ITimeline[]>>(`cameras/${cameraId}/timeline`, instanceGet)
  const timeline = useMemo(() => {
    if (!data) return undefined
    if (!filter) return data.data
    return data.data.filter(item => item.formattedDate.includes(filter))
  }, [data, filter])
  if (!timeline && !error) return <Loading />

  return (
    <S.Container>
      {timeline?.map(item => (
        <CardTimeline
          key={item.formattedDate}
          day={item.day}
          formattedDate={item.formattedDate}
          timelineURL={item.timelineURL}
        />
      ))}
    </S.Container>
  )
}
