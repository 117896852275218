import styled, { css } from 'styled-components'
import * as CardCamera from '../../components/shared/Card/CardCamera/styles'
import * as Skeleton from '../../components/shared/Card/CardSkeleton/styles'
import { Button as DefaultButton } from '../../components/shared'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  position: relative;
  padding: ${props => props.theme.padding.p2};
  padding-bottom: ${props => props.theme.padding.p2};
  overflow-y: auto;
  & > ${CardCamera.Container} {
    margin-left: ${props => props.theme.margin.m2};
  }
  & > ${Skeleton.Container} {
    margin-left: ${props => props.theme.margin.m2};
  }
`

export const Button = styled(DefaultButton)`
  color: #000;
  border: none;
  width: 48%;
  ${({ gradient }) =>
    gradient &&
    css`
      background: transparent linear-gradient(90deg, #fffd38 0%, #ffd500 100%) 0% 0% no-repeat padding-box;
      border-radius: 5px 20px 20px 20px;
    `}
`
