import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import useSWR from 'swr'
import { instance } from '../../../../api'
import { dispatchPlayback, useSelectCameraPlayback } from '../../../../states'
import * as S from './styles'

interface TimeProps {
  url: string
  label: string
  day: string
  formattedDate: string
}

interface HoursProps {
  timelineURL: string
  day: string
  formattedDate: string
}

interface Hours {
  label: string
  url: string
}

interface CardTimelineProps {
  day: string
  formattedDate: string
  timelineURL: string
}

const Time = ({ url, label, day, formattedDate }: TimeProps) => {
  const { cameraId } = useParams<{ cameraId: string }>()
  const playbackUrl = useSelectCameraPlayback()
  const history = useHistory()
  const handleClick = () => {
    dispatchPlayback(url)
    history.push(`/cameras/camera/${cameraId}?day=${day}&label=${label}&formattedDate=${formattedDate}`)
  }

  useEffect(() => {
    const setSelected = () => {
      if (!history.location.search.includes(day)) return
      if (!history.location.search.includes(label)) return
      if (!playbackUrl) dispatchPlayback(url)
    }
    setSelected()
  }, [day, history.location.search, label, playbackUrl, url])

  return (
    <S.Time selected={playbackUrl === url} onClick={handleClick}>
      {label}
    </S.Time>
  )
}

const Hours = ({ timelineURL, day, formattedDate }: HoursProps) => {
  const { data: hours, error } = useSWR<Hours[]>(timelineURL, url =>
    instance.get<Hours[]>(url).then(({ data }) => data)
  )

  if (!hours && !error)
    return (
      <S.WrapperInfo>
        <S.InfoText>carregando...</S.InfoText>
      </S.WrapperInfo>
    )

  if (hours?.length === 0)
    return (
      <S.WrapperInfo>
        <S.InfoText>Não há horários disponíveis</S.InfoText>
      </S.WrapperInfo>
    )

  if (error || hours?.length === undefined)
    return (
      <S.WrapperInfo>
        <S.InfoText>Erro ao carregar horários</S.InfoText>
      </S.WrapperInfo>
    )

  return (
    <S.WrapperHours>
      {hours?.map((item, index) => (
        <Time
          key={`${item.label}-${index}`}
          url={item.url}
          label={item.label}
          day={day}
          formattedDate={formattedDate}
        />
      ))}
    </S.WrapperHours>
  )
}

export const CardTimeline = ({ day, formattedDate, timelineURL }: CardTimelineProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (history.location.search.includes(formattedDate.replace(/\s/g, '%20'))) setIsOpen(true)
  }, [history.location.search, formattedDate, setIsOpen])

  return (
    <S.Container>
      <S.Header onClick={() => setIsOpen(prev => !prev)}>
        <S.Day>{day}</S.Day>
        <S.DayString>{formattedDate}</S.DayString>
      </S.Header>
      {isOpen && <Hours timelineURL={timelineURL} day={day} formattedDate={formattedDate} />}
    </S.Container>
  )
}
