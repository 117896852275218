import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
const variants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.02,
      staggerChildren: 0.02,
    },
  },
}

const variantsItem = {
  hidden: { y: -20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}

export const Container = styled.div`
  width: 360px;
  min-height: 40px;
  background: linear-gradient(90deg, ${props => props.theme.camera.color}, ${props => props.theme.camera.highlight});
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 0;
  z-index: 1;
  user-select: none;
  min-height: fit-content;
  box-sizing: border-box;
`

export const Header = styled.div`
  width: 360px;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-top-left-radius: 7px;
  &:hover {
    cursor: pointer;
  }
`

export const WrapperInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 40px;
`

export const InfoText = styled.p`
  font-size: 16px;
  color: ${props => props.theme.colors.gray.color};
  text-transform: capitalize;
`

export const WrapperHours = styled(motion.div).attrs({
  variants,
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
})`
  display: flex;
  flex: 1;
  padding: 10px 0;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  align-items: center;
  gap: 1%;
  transform-origin: top;
`

export const Day = styled.div`
  width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 0px 0 16px 0;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.colors.gray.color};
  color: ${props => props.theme.colors.pureWhite.color};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DayString = styled.h2`
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.colors.gray.color};
  margin-left: 8px;
`

const selected = css`
  background-color: #727f8a;
`

export const Time = styled(motion.div).attrs({ variants: variantsItem })<{ selected: boolean }>`
  width: 23%;
  height: 30px;
  background-color: ${props => props.theme.colors.gray.color};
  color: ${props => props.theme.colors.pureWhite.color};
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
  transition-duration: 0.2s;
  user-select: none;
  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }
  ${props => props.selected && selected};
`
