import React, { DragEvent, useCallback, useMemo, useState } from 'react'
import { useMosaicPlayState } from '../../../../states'
import { MosaicPositions, replaceCameraPosition } from '../../../../states/useMosaicState'
import * as S from './styles'
import { BaseVideoProps } from './types'

const VIDEO_CONFIG = {
  file: {
    hlsVersion: '1.1.4',
  },
}

const Video: React.FC<BaseVideoProps> = ({ selected, url, layoutId, name, address, position = 0, isPlayback }) => {
  const [playing] = useMosaicPlayState()
  const [ready, setReady] = useState(false)
  const [showDetail, setShowDetail] = useState(false)
  const [zoom, setZoom] = useState(1)
  const [top, setTop] = useState(0)
  const [left, setLeft] = useState(0)

  const handleOnReady = useCallback(() => setReady(true), [])

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    const id = e.dataTransfer.getData('id')
    replaceCameraPosition(position as MosaicPositions, id)
  }
  const handleResetZoom = () => {
    setZoom(1)
    setLeft(0)
    setTop(0)
  }

  const doNotHavZoom = useMemo(() => zoom === 1, [zoom])
  return (
    <S.Container
      selected={selected}
      layoutId={layoutId}
      onMouseEnter={() => setShowDetail(true)}
      onMouseLeave={() => setShowDetail(false)}
      onDrop={handleDrop}
      onDragOver={(e: DragEvent<HTMLDivElement>) => e.preventDefault()}
    >
      <S.Wrapper ready={ready}>
        {url && (
          <S.Video
            onReady={handleOnReady}
            url={url}
            width="100%"
            height="100%"
            playing={playing}
            controls
            stopOnUnmount
            forceHLS={true}
            config={isPlayback ? undefined : VIDEO_CONFIG}
            style={{ transform: `scale(${zoom}) translate(${left}%,${top}%)` }}
          />
        )}
        {showDetail && (
          <S.Detail>
            <S.Name>{name}</S.Name>
            <S.Address>{address}</S.Address>
          </S.Detail>
        )}
      </S.Wrapper>
      {selected && (
        <S.BoxZoom>
          <S.Reset onClick={handleResetZoom} />
          <S.ZoomOut onClick={() => setZoom(prev => (prev > 1 ? prev - 0.1 : prev))} />
          <S.ZoomValue>{Math.floor(100 * zoom)}%</S.ZoomValue>
          <S.ZoomIn onClick={() => setZoom(prev => (prev < 2 ? prev + 0.1 : prev))} />
          <S.ArrowLeft disabled={doNotHavZoom} onClick={() => setLeft(prev => (zoom > 1 ? prev + zoom * 1.1 : prev))} />
          <S.ArrowUp disabled={doNotHavZoom} onClick={() => setTop(prev => (zoom > 1 ? prev + zoom * 1.1 : prev))} />
          <S.ArrowDown disabled={doNotHavZoom} onClick={() => setTop(prev => (zoom > 1 ? prev - zoom * 1.1 : prev))} />
          <S.ArrowRight
            disabled={doNotHavZoom}
            onClick={() => setLeft(prev => (zoom > 1 ? prev - zoom * 1.1 : prev))}
          />
        </S.BoxZoom>
      )}
    </S.Container>
  )
}

export default Video
