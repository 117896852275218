import { createLogic } from 'redux-logic'
import {
  createChatSuccess,
  CREATE_CHAT,
  FETCH_OR_CREATE_CHAT,
  fetchChatByAlertIdSuccess,
  joinChatSuccess,
  JOIN_CHAT,
  listChatsSuccess,
  LIST_CHATS,
  sendMessageSuccess,
  SEND_MESSAGE,
  createChat,
} from '.'
import { asyncOperationError } from '../error-handling'

export const createChatLogic = ({ http, localStorage }) => {
  return createLogic({
    type: CREATE_CHAT,
    process({ action }, dispatch, done) {
      const token = localStorage.getItem('token')
      const chat = action.payload.chat
      http
        .post(`/chats`, chat, { headers: { 'x-access-token': token } })
        .then(res => res.data)
        .then(createdChat => dispatch(createChatSuccess(createdChat)))
        .catch(exception => dispatch(asyncOperationError({ logic: 'createChatLogic', exception })))
        .finally(done)
    },
  })
}

export const listChatsLogic = ({ http, localStorage }) => {
  return createLogic({
    type: LIST_CHATS,
    process(_, dispatch, done) {
      const token = localStorage.getItem('token')
      http
        .get(`/chats/mine`, { headers: { 'x-access-token': token } })
        .then(res => res.data)
        .then(chats => dispatch(listChatsSuccess(chats)))
        .catch(exception => dispatch(asyncOperationError({ logic: 'listChatsLogic', exception })))
        .finally(done)
    },
  })
}

export const sendMessageLogic = ({ http, localStorage }) => {
  return createLogic({
    type: SEND_MESSAGE,
    process({ action }, dispatch, done) {
      const token = localStorage.getItem('token')
      const { chatId, body } = action.payload
      http
        .post(`/chats/${chatId}/messages`, { body }, { headers: { 'x-access-token': token } })
        .then(res => res.data)
        .then(msg => dispatch(sendMessageSuccess(chatId, msg)))
        .catch(exception => dispatch(asyncOperationError({ logic: 'sendMessageLogic', exception })))
        .finally(done)
    },
  })
}

export const joinChatLogic = ({ http, localStorage }) => {
  return createLogic({
    type: JOIN_CHAT,
    process({ action }, dispatch, done) {
      const token = localStorage.getItem('token')
      const { chatId, user } = action.payload
      http
        .post(`/chats/${chatId}/join`, {}, { headers: { 'x-access-token': token } })
        .then(res => res.data)
        .then(() => dispatch(joinChatSuccess(chatId, user)))
        .catch(exception => dispatch(asyncOperationError({ logic: 'joinChatLogic', exception })))
        .finally(done)
    },
  })
}

export const fetchOrCreateChatLogic = ({ http, localStorage }) => {
  return createLogic({
    type: FETCH_OR_CREATE_CHAT,
    process({ action }, dispatch, done) {
      const token = localStorage.getItem('token')
      const { participantsIds, alert, enhancements } = action.payload
      http
        .get(`/alerts/${alert._id}/chats`, { headers: { 'x-access-token': token } })
        .then(res => res.data)
        .then(chat => {
          if (chat) {
            dispatch(fetchChatByAlertIdSuccess(chat, enhancements))
          } else {
            dispatch(createChat({ participantsIds, meta: { occurrence: alert } }))
          }
        })
        .catch(exception => dispatch(asyncOperationError({ logic: 'fetchChatByAlertIdLogic', exception })))
        .finally(done)
    },
  })
}

export default deps => [
  createChatLogic(deps),
  listChatsLogic(deps),
  sendMessageLogic(deps),
  joinChatLogic(deps),
  fetchOrCreateChatLogic(deps),
]
