import useSWF from '@raulpesilva/swf'
import { useCallback } from 'react'
import { instancePost } from '../../api/utils'
import LocalStorage from '../../local-storage'
import { Decoder } from '../../secure'
import { dispatchUserState, User, useTokenState } from '../../states'

interface LoginProps {
  data: { token: string }
}

export const useLogin = () => {
  const { loading, send, error } = useSWF<LoginProps>('/security/auth', instancePost)
  const [token, setToken] = useTokenState()

  const login = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      try {
        const { result } = await send({ email, password, noCache: true })
        if (result?.data?.token) {
          const receivedToken = result.data.token
          setToken(receivedToken)
          LocalStorage.setItem('token', receivedToken)
          dispatchUserState(Decoder.decode<User>(receivedToken))
        }
      } catch {}
    },
    [send, setToken]
  )

  const logout = useCallback(() => {
    setToken(null)
    LocalStorage.removeItem('token')
    dispatchUserState({} as User)
  }, [setToken])

  const restoreSession = useCallback(() => {
    token && dispatchUserState(Decoder.decode<User>(token))
  }, [token])

  return { login, logout, loading, token, restoreSession, error }
}
